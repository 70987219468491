import { useSetRecoilState } from "recoil";
import { currentPathnameState } from "../states/NavigationStates";
import { useEffect } from "react";

const ComplexElement = ({ children, ...initProps }) => {
  const setCurrentUrl = useSetRecoilState(currentPathnameState);

  useEffect(() => {
    setCurrentUrl(initProps.props.location.pathname);
  }, [initProps.props, setCurrentUrl]);

  return children;
};

export default ComplexElement;
