import { atom } from "recoil";

export const currentPathnameState = atom({
  key: "currentPathname",
  default: "/",
});

/**
 * A state that when updated should be used as an effect that causes the user to be pushed down to the application section.
 */
export const applyNowNavigationState = atom({
  key: "applyNowNavigation",
  default: 0,
});

/**
 * A state that when updated should be used as an effect that causes the user to be pushed down to the more info section.
 */
export const findOutMoreNavigationState = atom({
  key: "findOutMoreNavigation",
  default: 0,
});
