exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-career-development-js": () => import("./../../../src/pages/blog/career-development.js" /* webpackChunkName: "component---src-pages-blog-career-development-js" */),
  "component---src-pages-blog-confidence-building-js": () => import("./../../../src/pages/blog/confidence-building.js" /* webpackChunkName: "component---src-pages-blog-confidence-building-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-prismic-blog-article-uid-js": () => import("./../../../src/pages/blog/{prismicBlogArticle.uid}.js" /* webpackChunkName: "component---src-pages-blog-prismic-blog-article-uid-js" */),
  "component---src-pages-blog-self-improvement-js": () => import("./../../../src/pages/blog/self-improvement.js" /* webpackChunkName: "component---src-pages-blog-self-improvement-js" */),
  "component---src-pages-blog-success-stories-js": () => import("./../../../src/pages/blog/success-stories.js" /* webpackChunkName: "component---src-pages-blog-success-stories-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-for-individuals-index-js": () => import("./../../../src/pages/for-individuals/index.js" /* webpackChunkName: "component---src-pages-for-individuals-index-js" */),
  "component---src-pages-for-organisations-index-js": () => import("./../../../src/pages/for-organisations/index.js" /* webpackChunkName: "component---src-pages-for-organisations-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-who-we-are-index-js": () => import("./../../../src/pages/who-we-are/index.js" /* webpackChunkName: "component---src-pages-who-we-are-index-js" */)
}

